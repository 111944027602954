import {endpoints} from "../api";
import {
    GET_TIMEZONES_SUCCESS,
    GET_PROPERTIES_REQUEST,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTIES_ERROR,
    GET_USER_PROPERTIES_REQUEST,
    GET_USER_PROPERTIES_SUCCESS,
    GET_USER_PROPERTIES_ERROR,
    PATCH_PROPERTY_REQUEST,
    PATCH_PROPERTY_SUCCESS,
    PATCH_PROPERTY_ERROR,
    CLEAR_USER_PROPERTIES,
    SET_CURRENT_PROPERTY,
    CLEAR_CURRENT_PROPERTY,
    GET_PROPERTY_ROOMS_ERROR,
    GET_PROPERTY_ROOMS_SUCCESS,
    GET_PROPERTY_ROOMS_REQUEST,
    POST_CONNECT_ERROR, POST_CONNECT_SUCCESS, POST_CONNECT_REQUEST,
} from "./constants";

export const getTimezones = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getTimeZones,
    actions: {
        success: GET_TIMEZONES_SUCCESS,
    },
});

export const getProperties = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getProperties,
    actions: {
        request: GET_PROPERTIES_REQUEST,
        success: GET_PROPERTIES_SUCCESS,
        error: GET_PROPERTIES_ERROR,
    },
});

export const getUserProperties = idUser => ({
    type: 'API_CALL',
    endpoint: endpoints.getUserProperties,
    actions: {
        request: GET_USER_PROPERTIES_REQUEST,
        success: GET_USER_PROPERTIES_SUCCESS,
        error: GET_USER_PROPERTIES_ERROR,
    },
    params: {
        idUser,
    },
});

export const getPropertyRooms = idProperty => ({
    type: 'API_CALL',
    endpoint: endpoints.getRooms,
    actions: {
        request: GET_PROPERTY_ROOMS_REQUEST,
        success: GET_PROPERTY_ROOMS_SUCCESS,
        error: GET_PROPERTY_ROOMS_ERROR,
    },
    payload: {
        idProperty,
    },
    params: {
        idProperty,
    },
});

export const patchProperty = (idProperty, formValues, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.patchProperty,
    actions: {
        request: PATCH_PROPERTY_REQUEST,
        success: PATCH_PROPERTY_SUCCESS,
        successCallback,
        error: PATCH_PROPERTY_ERROR,
    },
    params: { idProperty },
    body: formValues,
    formName,
});

export const postConnect = (idProperty, formValues, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.postConnect,
    actions: {
        request: POST_CONNECT_REQUEST,
        success: POST_CONNECT_SUCCESS,
        successCallback,
        error: POST_CONNECT_ERROR,
    },
    params: { idProperty },
    body: formValues,
    formName,
});

export const clearUserProperties = () => ({
    type: CLEAR_USER_PROPERTIES,
});

export const setEditedProperty = property => ({
    type: SET_CURRENT_PROPERTY,
    property,
});

export const clearEditedProperty = () => ({
    type: CLEAR_CURRENT_PROPERTY,
});
