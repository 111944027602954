import React, { Component } from 'react';
import { Map, List, Iterable } from 'immutable';
import { connect } from 'react-redux';
import {
    Form,
} from 'eksenia-lib/src/Form';
import Modal, {
    hideModal,
} from 'eksenia-lib/src/Modal';
import ModalWithForm from 'eksenia-lib/src/ModalWithForm';
import Tabs from 'eksenia-lib/src/Tabs';
import {EDIT_USER_FORM_NAME, EDIT_USER_MODAL_NAME, USER_PROPS} from "./constants";
import editUserForm from './editUserForm';
import {timezonesToOptions} from "../utils/locale";
import {
    createUser,
    clearEditedUser,
    getUsers,
} from './actions';
import {
    getUserProperties,
    clearUserProperties,
} from '../Properties/actions';
import PropertyList from "../Properties/PropertyList";
import EditProperty from "../Properties/EditProperty";

export class EditUser extends Component {

    constructor() {
        super(...arguments);

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSaveUserClick = this.handleSaveUserClick.bind(this);
        this.createUserCallback = this.createUserCallback.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            getUserProperties,
            editedUser,
        } = this.props;

        const prevUser = prevProps.editedUser;

        if (editedUser && !prevUser) {
            getUserProperties(editedUser.get(USER_PROPS.ID));
        }
    }

    handleSaveUserClick(formValues, formName) {
        const {
            createUser,
            editedUser,
        } = this.props;

        if (!editedUser) {
            createUser(formValues.toJS(), formName, this.createUserCallback);
        }
    }

    createUserCallback() {
        const {
            getUsers,
        } = this.props;

        getUsers();
    }

    handleCloseModal() {
        const {
            hideModal,
            clearEditedUser,
            clearUserProperties,
        } = this.props;

        clearEditedUser();
        hideModal();
        clearUserProperties();
    }

    renderTabs() {
        const {
            editedUser,
            timezones,
        } = this.props;

        const isExistingUser = Iterable.isIterable(editedUser);
        const timezoneOptions = timezonesToOptions(timezones.toJS());

        return [
            {
                defaultActive: true,
                id: 'details',
                label: 'User details',
                content: (
                    <Form
                        key="details"
                        blueprint={editUserForm(editedUser || Map(), timezoneOptions)}
                        onSubmit={this.handleSaveUserClick}
                    />
                ),
            },
            {
                id: 'properties',
                label: 'Properties',
                content: <PropertyList userProperties />,
            enabled: isExistingUser,
            }
        ]
    }

    render() {
        const {
            editedUser,
        } = this.props;

        const isExistingUser = Iterable.isIterable(editedUser);
        const headerText = isExistingUser
            ? `User ${editedUser.get(USER_PROPS.EMAIL)}`
            : "New user"

        return (
            <ModalWithForm
                id={EDIT_USER_MODAL_NAME}
                headerText={headerText}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={[EDIT_USER_FORM_NAME]}
            >
                <Tabs
                    groupName="edit-user"
                    tabs={this.renderTabs()}
                    activeForms={[EDIT_USER_FORM_NAME]}
                />
                <EditProperty />
            </ModalWithForm>
        )
    }

}

const mapStateToProps = (store) => {
    const usersReducer = store.usersReducer;
    const propertiesReducer = store.propertiesReducer;

    return {
        editedUser: usersReducer.get('editedUser'),
        timezones: propertiesReducer.get('timezones') || List(),
    }
}

const mapDispatchToProps = {
    hideModal,
    createUser,
    clearEditedUser,
    getUsers,
    getUserProperties,
    clearUserProperties,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser)