import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { composeClassName } from 'utils';
import Icon from "eksenia-lib/src/Icon";

export class VerticalNavigation extends Component {

    constructor() {
        super(...arguments);

        this.onCollapseClick = this.onCollapseClick.bind(this);
        this.state = {
            collapsed: false,
        }
    }

    onCollapseClick() {
        this.setState({
            collapsed: !this.state.collapsed,
        })
    }

    render() {
        const {
            location,
        } = this.props;
        const {
            collapsed,
        } = this.state;

        return (
            <div className={composeClassName(
                'vertical-navigation',
                collapsed ? 'collapsed-navigation' : 'open-navigation')
            } >
                <div className="vertical-navigation-content">
                    <div className="logo">
                        <img
                            src={collapsed ? 'eksenia-logo-icon.svg' : 'eksenia-logo.svg'}
                            alt="Eksenia backoffice"
                        />
                    </div>

                    <div
                        className="collapse-control"
                        onClick={this.onCollapseClick}
                    >
                        <Icon
                            name={collapsed ? "expandPassive" : "collapsePassive"}
                            type="collapse"
                        />
                    </div>

                    <nav>
                        <Link
                            to='/'
                            className={composeClassName('nav-item', location.pathname === '/' ? 'active' : '')}
                        >
                            <Icon name="dashboard" type="vertical-nav" />
                            <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                Dashboard
                            </span>
                        </Link>
                        <Link
                            to='/users'
                            className={composeClassName('nav-item', location.pathname === '/users' ? 'active' : '')}
                        >
                            <Icon name="guests" type="vertical-nav" />
                            <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                Users
                            </span>
                        </Link>
                        <Link
                            to='/properties'
                            className={composeClassName('nav-item', location.pathname === '/properties' ? 'active' : '')}
                        >
                            <Icon name="property" type="vertical-nav" />
                            <span className={composeClassName('nav-label', collapsed ? 'hidden' : 'visible')}>
                                Properties
                            </span>
                        </Link>
                    </nav>
                </div>
            </div>
        );
    }
}

export default VerticalNavigation