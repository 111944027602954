export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const ADD_EDITED_USER = 'ADD_EDITED_USER';
export const CLEAR_EDITED_USER = 'CLEAR_EDITED_USER';

export const EDIT_USER_MODAL_NAME = 'EDIT_USER_MODAL_NAME';
export const EDIT_USER_FORM_NAME = 'EDIT_USER_FORM_NAME';

export const USER_PROPS = {
    ID: 'ID',
    EMAIL: 'Email',
    CREATED: 'Created',
    LANGUAGE: 'Language',
    IS_ACTIVATED: 'IsActivated',
    PASSWORD: 'PWD',
    TIME_ZONE: 'IANATimeZoneName',
    UNIT_LIMIT: 'UnitLimit',
    IS_ADMIN: 'IsAdmin',
};
