export default {
    getUser: {
        endpoint: 'backoffice/user',
        method: 'GET',
    },
    logIn: {
        endpoint: 'backoffice/log_in',
        method: 'POST',
    },
    logOut: {
        endpoint: 'backoffice/log_out',
        method: 'POST',
    },
    createUser: {
        endpoint: 'backoffice/create_user',
        method: 'POST',
    },
    getUsers: {
        endpoint: 'backoffice/users',
        method: 'GET',
    },
    getTimeZones: {
        endpoint: 'codelist/timezone',
        method: 'GET',
    },
    getProperties: {
        endpoint: 'backoffice/properties',
        method: 'GET',
    },
    getUserProperties: {
        endpoint: 'backoffice/properties/user/:idUser',
        method: 'GET',
    },
    patchProperty: {
        endpoint: 'backoffice/property/:idProperty',
        method: 'PATCH',
    },
    getImpersonateUser: {
        endpoint: 'backoffice/impersonate/:idUser',
        method: 'POST',
    },
    getRooms: {
        endpoint: 'backoffice/property/:idProperty/rooms',
        method: 'GET',
    },
    postConnect: {
        endpoint: 'backoffice/property/:idProperty/connect',
        method: 'POST',
    },
}
