export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_TIME_FORMAT = 'DD.MM.YYYY. - HH:mm';

export const LANGUAGE_CODES = {
    ENG: 'en',
    SER: 'sr',
    MNE: 'cnr',
};
export const LANGUAGE_NAMES = {
    [LANGUAGE_CODES.ENG]: 'English',
    [LANGUAGE_CODES.SER]: 'Serbian',
    [LANGUAGE_CODES.MNE]: 'Montenegrin',
};
