import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {EDIT_PROPERTY_FORM_NAME, PROPERTY_PROPS, PROPERTY_ROOMS_PROP, ROOM_PROPS} from "./constants";

export default (initialValues) => ({
    formName: EDIT_PROPERTY_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'section',
            name: 'Units',
            fields: [
                {
                    type: 'number',
                    name: PROPERTY_PROPS.UNIT_LIMIT,
                    initialValue: initialValues.get(PROPERTY_PROPS.UNIT_LIMIT),
                    label: 'Unit limit',
                    validations: ['required'],
                },
            ],
        }, {
            type: 'section',
            name: 'Channel manager mapping',
            fields: [
                {
                    type: 'input',
                    name: PROPERTY_PROPS.CM_PROPERTY_ID,
                    initialValue: initialValues.get(PROPERTY_PROPS.CM_PROPERTY_ID) || '',
                    label: 'Channel manager property ID',
                },
                {
                    type: 'inputMatrix',
                    name: PROPERTY_ROOMS_PROP,
                    initialValue: initialValues.get(PROPERTY_ROOMS_PROP),
                    formatters: ['cm-room-mapping'],
                    headers: [
                        {
                            id: ROOM_PROPS.ID,
                            text: 'Room ID',
                        },
                        {
                            id: ROOM_PROPS.CM_ROOM_ID,
                            text: 'Channel manager room ID',
                        },
                        {
                            id: ROOM_PROPS.CM_RATE_ID,
                            text: 'Channel manager rate ID',
                        },
                    ],
                    inputs: {
                        id: (listName, index, value) => value.get('ID'),
                        fields: [
                            {
                                type: 'custom',
                                name: ROOM_PROPS.ID,
                                render: value => {
                                    return (initialValues.get(PROPERTY_ROOMS_PROP).find(room => room.ID === value.value) || {}).Name
                                }
                            },
                            {
                                type: 'input',
                                name: ROOM_PROPS.CM_ROOM_ID,
                            },
                            {
                                type: 'input',
                                name: ROOM_PROPS.CM_RATE_ID,
                            },
                        ],
                    }
                },
            ]
        },
    ]
})
