import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import {
    EDIT_PROPERTY_MODAL_NAME, PROPERTY_PROPS,
} from './constants';
import Table from "eksenia-lib/src/Table";
import {
    setEditedProperty,
} from './actions';
import moment from "moment";
import {DISPLAY_DATE_TIME_FORMAT} from "../utils";
import localeMessages from './messages';

const tableHeader = [
    {
        content: localeMessages.name,
        key: 'name',
    },
    {
        content: localeMessages.created,
        key: 'created',
    },
    {
        content: localeMessages.owner,
        key: 'owner',
    },
    {
        content: localeMessages.unitLimit,
        key: 'unitLimit',
    },
]

export class PropertyList extends Component {

    handleOpenEditPropertyModal(property) {
        const {
            setEditedProperty,
            showModal,
        } = this.props;

        setEditedProperty(property);
        showModal(EDIT_PROPERTY_MODAL_NAME);
    }

    prepareTableRows() {
        const {
            properties,
        } = this.props;

        return properties.map(property => {
            const id = property.get(PROPERTY_PROPS.ID);

            return {
                key: id,
                onClick: this.handleOpenEditPropertyModal.bind(this, property),
                cells: [
                    {
                        content: property.get(PROPERTY_PROPS.NAME),
                        key: 'name',
                    },
                    {
                        content: moment(property.get(PROPERTY_PROPS.CREATED)).format(DISPLAY_DATE_TIME_FORMAT),
                        key: 'created',
                    },
                    {
                        content: property.get(PROPERTY_PROPS.OWNER_EMAIL),
                        key: 'owner',
                    },
                    {
                        content: property.get(PROPERTY_PROPS.UNIT_LIMIT),
                        key: 'unitLimit',
                    },
                ]
            }
        });
    }

    render() {
        const {
            loadingProperties,
        } = this.props;

        if (loadingProperties) {
            return null;
        }

        return (
            <Table header={tableHeader} rows={this.prepareTableRows()} />
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    const propertiesReducer = state.propertiesReducer;
    const useUserProperties = ownProps.userProperties;

    return {
        properties: useUserProperties
            ? propertiesReducer.get('userProperties', List())
            : propertiesReducer.get('properties', List()),
        loadingProperties: useUserProperties
            ? propertiesReducer.get('loadingUserProperties', true)
            : propertiesReducer.get('loadingProperties', true),
    }
}

const mapDispatchToProps = {
    showModal,
    setEditedProperty,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList)
