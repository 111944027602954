import {fromJS, Iterable} from "immutable";
import {reduceWithDefault} from "utils";
import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    ADD_EDITED_USER,
    CLEAR_EDITED_USER,
    CREATE_USER_SUCCESS
} from "./constants";

const initialState = fromJS({
    users: [],
    loadingUsers: true,
    editedUser: false,
});

const reducers = {
    [GET_USERS_REQUEST]: (state) =>
        state.merge({
            loadingUsers: true,
        }),

    [GET_USERS_SUCCESS]: (state, { data }) =>
        state.merge({
            users: fromJS(data),
            loadingUsers: false,
        }),

    [GET_USERS_ERROR]: (state) =>
        state.merge({
            users: fromJS([]),
            loadingUsers: false,
        }),

    [CREATE_USER_SUCCESS]: (state, { data }) =>
        state.merge({
            editedUser: fromJS(data),
        }),

    [ADD_EDITED_USER]: (state, { editedUser }) =>
        state.merge({
            editedUser: Iterable.isIterable(editedUser)
                ? editedUser
                : initialState.get('editedUser')
            ,
        }),

    [CLEAR_EDITED_USER]: state =>
        state.merge({
            editedUser: initialState.get('editedUser'),
        }),
}

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
