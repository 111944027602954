import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';
import {EDIT_USER_FORM_NAME, USER_PROPS} from "./constants";
import {LANGUAGE_CODES, LANGUAGE_NAMES} from "../utils";

export default (initialValues, timezoneOptions) => ({
    formName: EDIT_USER_FORM_NAME,
    formatters: [INPUT_FORMATTERS.VERTICAL],
    fields: [
        {
            type: 'input',
            name: USER_PROPS.EMAIL,
            initialValue: initialValues.get(USER_PROPS.EMAIL),
            label: 'Email address',
            validations: ['required'],
            disabled: !!initialValues.get(USER_PROPS.EMAIL),
        },
        !initialValues.get(USER_PROPS.EMAIL) && {
            type: 'input',
            name: USER_PROPS.PASSWORD,
            initialValue: initialValues.get(USER_PROPS.PASSWORD),
            label: 'Initial password',
            validations: ['required'],
        },
        {
            type: 'select',
            name: USER_PROPS.LANGUAGE,
            initialValue: initialValues.get(USER_PROPS.LANGUAGE),
            label: 'Language',
            validations: ['required'],
            options: [
                {
                    key: LANGUAGE_CODES.ENG,
                    value: LANGUAGE_CODES.ENG,
                    text: LANGUAGE_NAMES[LANGUAGE_CODES.ENG],
                },
                {
                    key: LANGUAGE_CODES.SER,
                    value: LANGUAGE_CODES.SER,
                    text: LANGUAGE_NAMES[LANGUAGE_CODES.SER],
                },
                {
                    key: LANGUAGE_CODES.MNE,
                    value: LANGUAGE_CODES.MNE,
                    text: LANGUAGE_NAMES[LANGUAGE_CODES.MNE],
                },
            ],
        },
        !initialValues.get(USER_PROPS.EMAIL) && {
            type: 'select',
            name: USER_PROPS.TIME_ZONE,
            initialValue: 'Europe/Belgrade',
            label: 'Time zone',
            validations: ['required'],
            options: timezoneOptions,
        },
        !initialValues.get(USER_PROPS.EMAIL) && {
            type: 'number',
            name: USER_PROPS.UNIT_LIMIT,
            initialValue: 0,
            label: 'Unit limit',
            validations: ['required'],
        },
    ].filter( it => it)
})
