import React, { Component } from 'react';
import Page from "eksenia-lib/src/Page";

export default class Dashboard extends Component {
    render() {
        return (
            <Page>
                Dashboard
            </Page>
        )
    }
}