import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from "immutable";
import Modal, {hideModal} from "eksenia-lib/src/Modal";
import {
    clearEditedProperty,
    patchProperty,
    getProperties,
    getUserProperties, getPropertyRooms,
    postConnect,
} from './actions';
import ModalWithForm from "eksenia-lib/src/ModalWithForm";
import {
    EDIT_PROPERTY_FORM_NAME,
    EDIT_PROPERTY_MODAL_NAME,
    PROPERTY_PROPS,
    PROPERTY_ROOMS_PROP,
    ROOM_PROPS
} from "./constants";
import {Form} from "eksenia-lib/src/Form";
import editPropertyForm from "./editPropertyForm";
import {USER_PROPS} from "../Users/constants";

export class EditProperty extends Component {

    constructor() {
        super(...arguments);

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSavePropertyClick = this.handleSavePropertyClick.bind(this);
        this.savePropertyCallback = this.savePropertyCallback.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            getPropertyRooms,
            editedProperty,
        } = this.props
        const editedPropertyId = editedProperty.get('ID');

        if (prevProps.editedProperty.get('ID') !== editedPropertyId) {
            getPropertyRooms(editedPropertyId);
        }

    }

    handleSavePropertyClick(formValues, formName) {
        const {
            patchProperty,
            editedProperty,
            postConnect,
            rooms,
        } = this.props;

        if (editedProperty.get(PROPERTY_PROPS.UNIT_LIMIT) !== formValues.get(PROPERTY_PROPS.UNIT_LIMIT)) {
            patchProperty(
                editedProperty.get(PROPERTY_PROPS.ID),
                {
                    "op_list": [
                        {
                            op: 'replace',
                            path: `/${PROPERTY_PROPS.UNIT_LIMIT}`,
                            value: formValues.get(PROPERTY_PROPS.UNIT_LIMIT),
                        },
                    ]
                },
                formName,
                this.savePropertyCallback
            );
        }

        const haveRoomsChanged = () => {
            const formRooms = formValues.get(PROPERTY_ROOMS_PROP)
            return !!rooms.find(room => {
                const formRoom = formRooms.find(formRoom => formRoom.get(ROOM_PROPS.ID) === room[ROOM_PROPS.ID])
                return formRoom.get(ROOM_PROPS.CM_ROOM_ID) !== room[ROOM_PROPS.CM_ROOM_ID] ||
                    formRoom.get(ROOM_PROPS.CM_RATE_ID) !== room[ROOM_PROPS.CM_RATE_ID]
            })
        }

        if ((editedProperty.get(PROPERTY_PROPS.CM_PROPERTY_ID) !== formValues.get(PROPERTY_PROPS.CM_PROPERTY_ID) &&
            !(editedProperty.get(PROPERTY_PROPS.CM_PROPERTY_ID) === null && formValues.get(PROPERTY_PROPS.CM_PROPERTY_ID) === '')) ||
            haveRoomsChanged()
        ) {
            postConnect(
                editedProperty.get(PROPERTY_PROPS.ID),
                {
                    [PROPERTY_PROPS.CM_PROPERTY_ID]: formValues.get(PROPERTY_PROPS.CM_PROPERTY_ID),
                    [PROPERTY_ROOMS_PROP]: formValues.get(PROPERTY_ROOMS_PROP).toJS(),
                },
                formName,
                this.savePropertyCallback
            )
        }
    }

    savePropertyCallback() {
        const {
            getProperties,
            userId,
            getUserProperties,
        } = this.props;

        getProperties();

        if (userId) {
            getUserProperties(userId);
        }
    }

    handleCloseModal() {
        const {
            hideModal,
            clearEditedProperty,
        } = this.props;

        clearEditedProperty();
        hideModal();
    }

    render() {
        const {
            editedProperty,
            rooms,
            isLoading,
        } = this.props;

        const headerText = `Property ${editedProperty.get(PROPERTY_PROPS.NAME)}`

        if (isLoading) {
            return null
        }

        const formValues = editedProperty.set(PROPERTY_ROOMS_PROP, rooms) || Map()

        return (
            <ModalWithForm
                id={EDIT_PROPERTY_MODAL_NAME}
                headerText={headerText}
                formatters={[Modal.SIZE.BIG]}
                onClose={this.handleCloseModal}
                activeForms={[EDIT_PROPERTY_FORM_NAME]}
            >
                <Form
                    key="details"
                    blueprint={editPropertyForm(formValues)}
                    onSubmit={this.handleSavePropertyClick}
                />
            </ModalWithForm>
        )
    }
}

const mapStateToProps = (store) => {
    const propertiesReducer = store.propertiesReducer;
    const usersReducer = store.usersReducer;
    const editedProperty = propertiesReducer.get('editedProperty') || Map()

    return {
        isLoading: propertiesReducer.get('loadingRooms'),
        editedProperty,
        userId: usersReducer.getIn(['editedUser', USER_PROPS.ID]),
        rooms: propertiesReducer.get('rooms').toJS()[editedProperty.get('ID')]
    }
}

const mapDispatchToProps = {
    hideModal,
    clearEditedProperty,
    patchProperty,
    getProperties,
    getUserProperties,
    getPropertyRooms,
    postConnect,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProperty)
