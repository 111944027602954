import React, { Component } from 'react';
import {connect} from "react-redux";
import { logOut } from "User";
import { withRouter } from 'react-router';
import { composeClassName } from 'utils';
import Dropdown from "eksenia-lib/src/Dropdown";
import Icon from "eksenia-lib/src/Icon";

export class Header extends Component {

    render() {
        const {
            logOut,
            location,
        } = this.props;

        return (
            <header className="header">
                <div className="breadcrumbs">
                    {location.pathname.replace('/', '') || 'Dashboard'}
                </div>
                <div className="menus">
                    <div className="header-menu">
                        <Dropdown
                            title={(() => (
                                <button
                                    className={composeClassName('header-menu-item')}
                                    type="button"
                                >
                                    <Icon
                                        name="headerSettings"
                                        type="header-menu"
                                    />
                                </button>
                            ))}
                            items={[
                                {
                                    id: 'logOut',
                                    text: 'Log out',
                                    onClick: logOut,
                                },
                            ]}
                        />
                    </div>
                </div>
            </header>
        );        
    }
}

const mapDispatchToProps = { logOut };

export default withRouter(connect(null, mapDispatchToProps)(Header));
