export const GET_TIMEZONES_SUCCESS = 'GET_TIMEZONES_SUCCESS';
export const GET_PROPERTIES_REQUEST = 'GET_PROPERTIES_REQUEST';
export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS';
export const GET_PROPERTIES_ERROR = 'GET_PROPERTIES_ERROR';
export const GET_USER_PROPERTIES_REQUEST = 'GET_USER_PROPERTIES_REQUEST';
export const GET_USER_PROPERTIES_SUCCESS = 'GET_USER_PROPERTIES_SUCCESS';
export const GET_USER_PROPERTIES_ERROR = 'GET_USER_PROPERTIES_ERROR';
export const PATCH_PROPERTY_REQUEST = 'PATCH_PROPERTY_REQUEST';
export const PATCH_PROPERTY_SUCCESS = 'PATCH_PROPERTY_SUCCESS';
export const PATCH_PROPERTY_ERROR = 'PATCH_PROPERTY_ERROR';
export const CLEAR_USER_PROPERTIES = 'CLEAR_USER_PROPERTIES';
export const SET_CURRENT_PROPERTY = 'SET_CURRENT_PROPERTY';
export const CLEAR_CURRENT_PROPERTY = 'CLEAR_CURRENT_PROPERTY';
export const GET_PROPERTY_ROOMS_REQUEST = 'GET_PROPERTY_ROOMS_REQUEST';
export const GET_PROPERTY_ROOMS_SUCCESS = 'GET_PROPERTY_ROOMS_SUCCESS';
export const GET_PROPERTY_ROOMS_ERROR = 'GET_PROPERTY_ROOMS_ERROR';
export const POST_CONNECT_REQUEST = 'POST_CONNECT_REQUEST';
export const POST_CONNECT_SUCCESS = 'POST_CONNECT_SUCCESS';
export const POST_CONNECT_ERROR = 'POST_CONNECT_ERROR';

export const EDIT_PROPERTY_MODAL_NAME = 'EDIT_PROPERTY_MODAL_NAME';
export const EDIT_PROPERTY_FORM_NAME = 'EDIT_PROPERTY_FORM_NAME';

export const PROPERTY_PROPS = {
    ID: 'ID',
    UNIT_LIMIT: 'UnitLimit',
    OWNER_ID: 'UserID',
    CREATED: 'Created',
    NAME: 'Name',
    OWNER_EMAIL: 'OwnerEmail',
    CM_PROPERTY_ID: 'CMPropertyID',
};

export const ROOM_PROPS = {
    ID: 'ID',
    NAME: 'Name',
    CM_ROOM_ID: 'CMRoomTypeID',
    CM_RATE_ID: 'CMRatePlanID',
}

export const PROPERTY_ROOMS_PROP = 'Rooms';
