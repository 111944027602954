import { combineReducers } from 'redux';

import {
    reducer as formReducer,
} from 'eksenia-lib/src/Form';
import {
    reducer as modalReducer,
} from 'eksenia-lib/src/Modal';
import {
    reducer as tabsReducer,
} from 'eksenia-lib/src/Tabs';
import {
    reducer as notificationsReducer,
} from 'eksenia-lib/src/Notifications';
import {
    reducer as fileReducer,
} from 'eksenia-lib/src/File';
import {
    reducer as userReducer,
} from 'User';
import {
    reducer as usersReducer,
} from 'Users';
import {
    reducer as propertiesReducer,
} from 'Properties';

export default combineReducers({
    formReducer,
    modalReducer,
    tabsReducer,
    notificationsReducer,
    fileReducer,
    userReducer,
    usersReducer,
    propertiesReducer,
});
