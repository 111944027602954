import React, { Component } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Notifications from 'eksenia-lib/src/Notifications';
import Login from "Login";
import ResetPassword from "ResetPassword";
import PrivateRoute from "../PrivateRoute";
import Dashboard from "../Dashboard";
import Users from "../Users";
import Properties from '../Properties';
import {getTimezones} from '../Properties/actions';

const basename = '';

export class Layout extends Component {

    componentDidMount() {
        this.props.getTimezones();
    }

    render() {
        return (
            <Router basename={basename}>
                <Switch>
                    <Route path="/login" component={ Login } />
                    <Route path="/reset_password/:token" component={ ResetPassword } />
                    <PrivateRoute path="/users" component={ Users } />
                    <PrivateRoute path="/properties" component={ Properties } />
                    <PrivateRoute path="/" component={ Dashboard }/>
                </Switch>
                <Notifications />
            </Router>
        );
    }

}

const mapStateToProps = store => {
    const userReducer = store.userReducer;

    return {
        user: userReducer.get('user'),
    }
};

const mapDispatchToProps = {
    getTimezones,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
