import React, {Component} from 'react';
import { Redirect, withRouter } from 'react-router';
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Header from 'Header';
import VerticalNavigation from "../VerticalNavigation";

class AuthCheck extends Component {

    render() {
        const {
            component: Component,
            location,
            user,
            ...restProps
        } = this.props;

        if (!user) {
            return null
        }
        if (!user.toJS().Email) {
            return (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: location }
                }}/>
            )
        }

        return (
            <Route
                { ...restProps }
                location={location}
                render={() =>
                    <div className="private-layout">
                        <VerticalNavigation location={location}/>

                        <div className="private-container">
                            <Header/>
                            <Component/>
                        </div>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = store => ({
    user: store.userReducer.get('user'),
});

const AuthCheckRedux = withRouter(connect(mapStateToProps)(AuthCheck));


const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route { ...rest } render={ () => <AuthCheckRedux component={ Component } /> } />;
};

export default PrivateRoute;