import React, {Component} from 'react';
import {connect} from "react-redux";
import Layout from "./Layout";
import {getUser} from "./User";
import {IntlProvider} from 'react-intl';

class App extends Component {
    componentDidMount() {
        const {
            user,
            getUser,
        } = this.props;

        if (!user) {
            getUser();
        }
    }

    componentDidUpdate() {
        const {
            loadingUser,
            user,
            getUser,
        } = this.props;

        if (!user && !loadingUser) {
            getUser();
        }
    }

    render() {
        const {
            loadingUser,
        } = this.props;

        if (loadingUser) {
            return null
        }

        return (
            <IntlProvider
                defaultLocale={'en'}
                locale={'en'}
                key={'en'}
                messages={{}}
            >
                <Layout/>
            </IntlProvider>
        );
    }
}


const mapStateToProps = store => {
    const {
        userReducer,
    } = store;

    const user = userReducer.get('user');

    return {
        user,
        loadingUser: userReducer.get('loadingUser'),
    }
};
const mapDispatchToProps = {
    getUser,
};


export default connect(mapStateToProps, mapDispatchToProps)(App);
