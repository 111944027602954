import {endpoints} from "../api";
import {
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    CREATE_USER_ERROR,
    CREATE_USER_SUCCESS, CREATE_USER_REQUEST, CLEAR_EDITED_USER, ADD_EDITED_USER
} from "./constants";

export const getUsers = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getUsers,
    actions: {
        request: GET_USERS_REQUEST,
        success: GET_USERS_SUCCESS,
        error: GET_USERS_ERROR,
    },
});

export const createUser = (values, formName, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.createUser,
    actions: {
        request: CREATE_USER_REQUEST,
        success: CREATE_USER_SUCCESS,
        error: CREATE_USER_ERROR,
        successCallback,
    },
    body: values,
    formName,
});

export const addEditedUser = editedUser => ({
    type: ADD_EDITED_USER,
    editedUser,
});

export const clearEditedUser = () => ({
    type: CLEAR_EDITED_USER,
});

export const impersonateUser = (idUser, successCallback) => ({
    type: 'API_CALL',
    endpoint: endpoints.getImpersonateUser,
    actions: {
        successCallback,
    },
    params: { idUser },
});

