import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import Page from "eksenia-lib/src/Page";
import PropertyList from "./PropertyList";
import {getProperties} from "./actions";
import EditProperty from "./EditProperty";
import './Properties.scss';

export class Properties extends Component {

    componentDidMount() {
        this.props.getProperties();
    }

    render() {
        return (
            <Page>
                <PropertyList />

                <EditProperty />
            </Page>
        )
    }
}

const mapDispatchToProps = {
    getProperties,
    showModal,
};

export default connect(undefined, mapDispatchToProps)(Properties)
