import React, { Component } from 'react';
import { connect } from 'react-redux';
import {List} from "immutable";
import {showModal, hideModal} from "eksenia-lib/src/Modal";
import Table from 'eksenia-lib/src/Table';
import Button from "eksenia-lib/src/Button";
import {EDIT_USER_MODAL_NAME, USER_PROPS} from "./constants";
import {INPUT_FORMATTERS} from "eksenia-lib/src/formatters";
import {DISPLAY_DATE_TIME_FORMAT, LANGUAGE_NAMES} from '../utils';
import {
    addEditedUser,
    impersonateUser,
} from './actions';
import moment from "moment";
import localeMessages from './messages';

const tableHeader = [
    {
        content: localeMessages.email,
        key: 'email',
    },
    {
        content: localeMessages.created,
        key: 'created',
    },
    {
        content: localeMessages.activated,
        key: 'activated',
    },
    {
        content: localeMessages.language,
        key: 'language',
    },
    {
        content: '',
        key: 'actions',
    },
]

export class UserList extends Component {

    handleOpenEditUserModal(user) {
        const {
            addEditedUser,
            showModal,
        } = this.props;

        addEditedUser(user);
        showModal(EDIT_USER_MODAL_NAME);
    }

    handleImpersonateUser(userId) {
        const {
            impersonateUser,
        } = this.props;

        impersonateUser(userId, this.impersonateUserSuccessCallback)
    }

    impersonateUserSuccessCallback(url) {
        console.log(url)
        const newWindow = window.open(url, '_blank');
        console.log(newWindow)
    }

    prepareTableRows() {
        const {
            users,
        } = this.props;

        return users.map(user => {
            const id = user.get(USER_PROPS.ID);

            return {
                key: id,
                onClick: this.handleOpenEditUserModal.bind(this, id),
                cells: [
                    {
                        content: user.get(USER_PROPS.EMAIL),
                        value: user.get(USER_PROPS.EMAIL),
                        key: 'name',
                    },
                    {
                        content: moment(user.get(USER_PROPS.CREATED)).format(DISPLAY_DATE_TIME_FORMAT),
                        value: moment(user.get(USER_PROPS.CREATED)),
                        key: 'created',
                    },
                    {
                        content: user.get(USER_PROPS.IS_ACTIVATED),
                        value: user.get(USER_PROPS.IS_ACTIVATED),
                        key: 'activated',
                    },
                    {
                        content: LANGUAGE_NAMES[user.get(USER_PROPS.LANGUAGE)],
                        value: LANGUAGE_NAMES[user.get(USER_PROPS.LANGUAGE)],
                        key: 'language',
                    },
                    {
                        content: (
                            <>
                                {!user.get(USER_PROPS.IS_ADMIN) &&
                                    <Button
                                        key="impersonate"
                                        formatters={[Button.TYPE.LINK, INPUT_FORMATTERS.HEIGHT.S]}
                                        onClick={event => {
                                            event.stopPropagation();
                                            this.handleImpersonateUser(id);
                                        }}
                                    >
                                        Impersonate
                                    </Button>
                                }
                            </>
                        ),
                        value: '',
                        key: 'actions',
                        className: 'actions',
                    },
                ]
            }
        });
    }

    render() {
        const {
            loadingUsers,
        } = this.props;

        if (loadingUsers) {
            return null;
        }

        return (
            <Table header={tableHeader} rows={this.prepareTableRows()} />
        )
    }

}

const mapStateToProps = store => {
    const usersReducer = store.usersReducer;

    return {
        users: usersReducer.get('users') || List(),
        loadingUsers: usersReducer.get('loadingUsers'),
    }
};

const mapDispatchToProps = {
    showModal,
    hideModal,
    addEditedUser,
    impersonateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
