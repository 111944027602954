import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'properties.name',
        defaultMessage: 'Name',
    },
    created: {
        id: 'properties.created',
        defaultMessage: 'Created',
    },
    owner: {
        id: 'properties.owner',
        defaultMessage: 'Owner',
    },
    unitLimit: {
        id: 'properties.unitLimit',
        defaultMessage: 'Unit limit',
    },
})
