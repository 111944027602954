import React, {Component} from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { logIn } from 'User';
import Input from 'eksenia-lib/src/Input';
import Button from 'eksenia-lib/src/Button';
import { INPUT_FORMATTERS } from 'eksenia-lib/src/formatters';

class Login extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            email: '',
            pwd: '',
            loginFailed: false,
            submitDisabled: false,
            emailEmptyError: false,
            pwdEmptyError: false,
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePwdChange = this.handlePwdChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.loginInProgress && !this.props.loginInProgress) {
            const user = this.props.user.toJS();
            this.setState({
                loginFailed: !user.Email,
                submitDisabled: false,
            });
        }
    }

    handleSubmit(evt) {
        evt.preventDefault();
        if (this.state.email.length === 0 || this.state.pwd.length === 0) {
            this.setState({
                emailEmptyError: this.state.email.length === 0,
                pwdEmptyError: this.state.pwd.length === 0,
            });
        } else {
            this.setState({
                submitDisabled: true,
                pwd: '',
            });
            this.props.logIn(this.state.email, this.state.pwd);
        }
    }

    handleEmailChange(value) {
        this.setState({
            email: value,
            emailEmptyError: false,
            loginFailed: false,
        });
    };

    handlePwdChange(value) {
        this.setState({
            pwd: value,
            pwdEmptyError: false,
            loginFailed: false,
        });
    }

    render() {
        if (this.props.user && this.props.user.toJS().Email) {
            return (
                <Redirect to={{
                    pathname: '/',
                    state: { from: this.props.location }
                }}/>
            );
        }

        return (
            <div className="login">
                <form onSubmit={this.handleSubmit}>
                    <Input
                        label="Email"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        formatters={[INPUT_FORMATTERS.VERTICAL]}
                    />

                    <Input
                        type="password"
                        value={this.state.pwd}
                        onChange={this.handlePwdChange}
                        label="Password"
                        formatters={[INPUT_FORMATTERS.VERTICAL]}
                    />

                    <Button
                        type="submit"
                        disabled={this.state.submitDisabled}
                    >
                        Log in
                    </Button>
                </form>
                {
                    this.state.emailEmptyError
                        ? <div>Email cannot be empty</div>
                        : undefined
                }
                {
                    this.state.pwdEmptyError
                        ? <div>Password cannot be empty</div>
                        : undefined
                }
                {
                    // TODO wrong credential vs internal error
                    this.state.loginFailed
                        ? <div>Login failed</div>
                        : undefined
                }

            </div>
        )
    }
}

const mapStateToProps = store => {
    const userReducer = store.userReducer;
    return {
        user: userReducer.get('user'),
        loginInProgress: userReducer.get('loginInProgress'),
    }
};
const mapDispatchToProps = { logIn };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
