import { defineMessages } from 'react-intl';

export default defineMessages({
    email: {
        id: 'users.email',
        defaultMessage: 'Email',
    },
    created: {
        id: 'users.created',
        defaultMessage: 'Created',
    },
    activated: {
        id: 'users.activated',
        defaultMessage: 'Activated',
    },
    language: {
        id: 'users.language',
        defaultMessage: 'Language',
    },
})
