import { fromJS, List } from 'immutable';

import { reduceWithDefault } from 'utils';
import {
    GET_TIMEZONES_SUCCESS,
    GET_PROPERTIES_REQUEST,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTIES_ERROR,
    GET_USER_PROPERTIES_REQUEST,
    GET_USER_PROPERTIES_SUCCESS,
    GET_USER_PROPERTIES_ERROR,
    PATCH_PROPERTY_SUCCESS,
    CLEAR_USER_PROPERTIES,
    SET_CURRENT_PROPERTY,
    CLEAR_CURRENT_PROPERTY,
    GET_PROPERTY_ROOMS_ERROR, GET_PROPERTY_ROOMS_SUCCESS, GET_PROPERTY_ROOMS_REQUEST,
} from './constants';

const initialState = fromJS({
    properties: [],
    editedProperty: false,
    loadingProperties: true,
    timezones: [],
    userProperties: [],
    loadingUserProperties: true,
    loadingRooms: true,
    rooms: {},
});

const reducers = {
    [GET_PROPERTIES_REQUEST]: (state) =>
        state.set('loadingProperties', true),

    [GET_PROPERTIES_SUCCESS]: (state, { data }) => {
        const properties = fromJS(data);
        return state.merge({
            properties,
            loadingProperties: false,
        })
    },

    [GET_PROPERTIES_ERROR]: (state) =>
        state.merge({
            properties: List(),
            loadingProperties: false,
        }),

    [GET_USER_PROPERTIES_REQUEST]: (state) =>
        state.set('loadingUserProperties', true),

    [GET_USER_PROPERTIES_SUCCESS]: (state, { data }) => {
        const userProperties = fromJS(data);
        return state.merge({
            userProperties,
            loadingUserProperties: false,
        })
    },

    [GET_USER_PROPERTIES_ERROR]: (state) =>
        state.merge({
            userProperties: List(),
            loadingUserProperties: false,
        }),

    [CLEAR_USER_PROPERTIES]: (state) =>
        state.set('userProperties', List()),

    [PATCH_PROPERTY_SUCCESS]: (state, { data }) =>
        state.set('editedProperty', fromJS(data)),

    [SET_CURRENT_PROPERTY]: (state, { property }) =>
        state.set('editedProperty', property),

    [CLEAR_CURRENT_PROPERTY]: (state) =>
        state.set('editedProperty', false),

    [GET_TIMEZONES_SUCCESS]: (state, { data }) =>
        state.set("timezones", fromJS(data)),

    [GET_PROPERTY_ROOMS_REQUEST]: (state) =>
        state.set('loadingRooms', true),

    [GET_PROPERTY_ROOMS_SUCCESS]: (state, { data, payload: { idProperty }}) => {
        const propertyRooms = fromJS(data);
        return state.merge({
            rooms: state.get('rooms').set(
                [idProperty], propertyRooms
            ),
            loadingRooms: false,
        })
    },

    [GET_PROPERTY_ROOMS_ERROR]: (state, { idProperty }) =>
        state.merge({
            rooms: state.get('rooms').delete(idProperty),
            loadingRooms: false,
        }),

};

export default (state = initialState, action) =>
    reduceWithDefault(state, action, reducers);
