import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    showModal,
} from 'eksenia-lib/src/Modal';
import Page from "eksenia-lib/src/Page";
import {
    EDIT_USER_MODAL_NAME,
} from './constants';
import Button from "eksenia-lib/src/Button";
import UserList from "./UserList";
import {getUsers} from "./actions";
import EditUser from "./EditUser";
import {PAGE_FORMATTERS} from "eksenia-lib/src/Page/Page";

export class Users extends Component {

    componentDidMount() {
        this.props.getUsers();
    }

    renderHeader() {
        const {
            showModal,
        } = this.props;

        return (
            <div>
                <Button onClick={showModal.bind(this, EDIT_USER_MODAL_NAME)}>
                    Create user
                </Button>
            </div>
        );
    }

    render() {
        return (
            <Page
                header={this.renderHeader()}
                formatters={[PAGE_FORMATTERS.WITH_HEADER]}
            >
                <UserList />

                <EditUser />
            </Page>
        )
    }
}

const mapDispatchToProps = {
    getUsers,
    showModal,
};

export default connect(undefined, mapDispatchToProps)(Users)