import {endpoints} from "api";
import {
    USR_GET_USER_ERROR,
    USR_GET_USER_REQUEST,
    USR_GET_USER_SUCCESS,
    USR_POST_LOG_IN_ERROR,
    USR_POST_LOG_IN_REQUEST,
    USR_POST_LOG_IN_SUCCESS,
    USR_POST_LOG_OUT_ERROR,
    USR_POST_LOG_OUT_REQUEST,
    USR_POST_LOG_OUT_SUCCESS,
    USR_POST_COMPLETE_PASSWORD_RESET_ERROR,
    USR_POST_COMPLETE_PASSWORD_RESET_REQUEST,
    USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS,
} from "./constants";

export const getUser = () => ({
    type: 'API_CALL',
    endpoint: endpoints.getUser,
    actions: {
        request: USR_GET_USER_REQUEST,
        success: USR_GET_USER_SUCCESS,
        error: USR_GET_USER_ERROR,
    },
});

export const logIn = (email, pwd) => ({
    type: 'API_CALL',
    endpoint: endpoints.logIn,
    actions: {
        request: USR_POST_LOG_IN_REQUEST,
        success: USR_POST_LOG_IN_SUCCESS,
        error: USR_POST_LOG_IN_ERROR,
    },
    body: {
        Email: email,
        Pwd: pwd,
    },
});

export const logOut = () => ({
    type: 'API_CALL',
    endpoint: endpoints.logOut,
    actions: {
        request: USR_POST_LOG_OUT_REQUEST,
        success: USR_POST_LOG_OUT_SUCCESS,
        error: USR_POST_LOG_OUT_ERROR,
    },
});

export const completePasswordReset = (token, pwd) => ({
    type: 'API_CALL',
    endpoint: endpoints.completePasswordReset,
    actions: {
        request: USR_POST_COMPLETE_PASSWORD_RESET_REQUEST,
        success: USR_POST_COMPLETE_PASSWORD_RESET_SUCCESS,
        error:  USR_POST_COMPLETE_PASSWORD_RESET_ERROR,
    },
    body: {
        Pwd: pwd,
        Token: token,
    },
});
